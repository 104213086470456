import type { IMenu } from '@gc/common-lib/api/header'
import { isArrayAndNotEmpty } from '@gc/common-lib/utils/lodash'
import Menu from '@gc/ui-components/menu'
import { useResponsive } from 'ahooks'
import type { MenuProps } from 'antd'
import { includes } from 'lodash'
import { useContext, useEffect, useState } from 'react'

import { SubMenuContext } from './index'

type TMenuItem = Required<MenuProps>['items'][number]

const MiddleMenu: React.FC<IMenu> = props => {
  const { subNavigation: middleMenuData } = props
  const responsive = useResponsive()
  const [openKeys, setOpenKeys] = useState([] as string[])
  const [expandedSecondMenuKey, setExpandedSecondMenuKey] = useState('')
  const [isClientLegacy, setIsClient] = useState(false)
  const onOpenChange = useContext(SubMenuContext)

  useEffect(() => {
    setIsClient(responsive?.legacy ?? false)
  }, [])

  useEffect(() => {
    /** On mobile mode, once expanded a second menu(change expandedSecondMenuKey value),
     * the related mobile dropdown will be appended as its children menu,
     * at this moment, bind the collapse event and add a class to mark it is rendered,
     * (use active class to control its display) */
    if (!isClientLegacy && expandedSecondMenuKey !== '') {
      const mobileDropdownArr = document.querySelectorAll(
        '.middle-menu .-title.mobile-dropdown:not(.hasClickEvent)'
      )

      mobileDropdownArr.forEach(item => {
        item.addEventListener('click', e => {
          const targetEl = e.target as HTMLElement
          if (targetEl.classList.contains('mobile-dropdown')) {
            e.preventDefault()
          }
          if (includes(item.className, 'active')) {
            item.className = '-title mobile-dropdown hasClickEvent'
          } else {
            const mobileActiveDropdownItem = document.querySelector(
              '.middle-menu .-title.mobile-dropdown.active'
            )

            if (mobileActiveDropdownItem) {
              mobileActiveDropdownItem.className =
                '-title mobile-dropdown hasClickEvent'
            }

            item.className = '-title mobile-dropdown hasClickEvent active'
          }
        })

        item.className = '-title mobile-dropdown hasClickEvent'
      })
    }
  }, [responsive, expandedSecondMenuKey])

  if (!isArrayAndNotEmpty(middleMenuData)) return null

  const getMenuItem = middleMenuData => {
    return middleMenuData.map(item => {
      const { childItemName, childItemLink, navigationHtml, childId } = item
      const linkTitle = (
        <a
          href={childItemLink}
          className="block h-[42px] leading-[42px] text-[15px] hover:text-[rgba(0,0,0,0.85)]"
        >
          {childItemName}
        </a>
      )
      return {
        id: `ant-submenu-${childId}`,
        key: childId,
        children: navigationHtml
          ? [
              {
                key: childId,
                label: (
                  <div
                    dangerouslySetInnerHTML={{ __html: navigationHtml }}
                    suppressHydrationWarning
                  />
                ),
                type: 'group'
              }
            ]
          : undefined,
        label:
          item?.childItemLink && (isClientLegacy || !navigationHtml)
            ? linkTitle
            : childItemName
      } as TMenuItem
    })
  }

  const middleMenuArr = getMenuItem(middleMenuData)

  const openChangeEvent = items => {
    const latestOpenKey = items.find(key => !includes(openKeys, key))
    setOpenKeys(latestOpenKey ? [latestOpenKey] : [])
    // close the active mobile dropdown menu
    const mobileActiveDropdownItem = document.querySelector(
      '.middle-menu .-title.mobile-dropdown.active'
    )
    if (mobileActiveDropdownItem) {
      mobileActiveDropdownItem.className =
        '-title mobile-dropdown hasClickEvent'
    }
    // change the expandedSecondMenuKey, will trigger useEffect hook
    setTimeout(() => {
      setExpandedSecondMenuKey(latestOpenKey || '')
    }, 1000)
  }

  return (
    <>
      {isArrayAndNotEmpty(middleMenuArr) && (
        <div className="middle-menu" suppressHydrationWarning>
          <hr className="mt-[10px] mb-[15px]" />
          <h3 className="m-[10px] text-[15px] font-bold">Shop By Category</h3>
          {isClientLegacy ? (
            <Menu
              className="header-middle-menu"
              mode="vertical"
              items={middleMenuArr}
              onOpenChange={onOpenChange}
            />
          ) : (
            <Menu
              className="header-middle-menu"
              mode="inline"
              items={middleMenuArr}
              openKeys={openKeys}
              onOpenChange={openChangeEvent}
            />
          )}
        </div>
      )}
      <style jsx global>{`
        .middle-menu .ant-menu-submenu-inline > ul.ant-menu-sub {
          padding-left: 20px;
          .col-wrap > a,
          .col > ul > li {
            padding-left: 30px;
          }
        }
        .megamenu-inner-wrap {
          line-height: 1.5;
          @media (max-width: 975.9px) {
            .-title.mobile-dropdown {
              &:before {
                position: absolute;
                top: 14px;
                left: 0px;
                content: '';
                display: block;
                height: 15px;
                width: 15px;
                background-size: 63% 100%;
                background-image: url(/assets/icons/header/arrow-right.svg);
                background-repeat: no-repeat;
                cursor: pointer;
                transition: all 0.25s ease-in-out;
                transform-origin: top left;
              }
              > a {
                pointer-events: none;
              }
              .mobile-hidden {
                display: none;
              }
              .mobile-hidden.accordion-content {
                padding-left: 30px;
              }
              &.active:before {
                left: 2px;
                transition: all 0.25s ease-in-out;
                transform: rotate(90deg) translateY(-50%);
                transform-origin: top left;
              }
              &.active .mobile-hidden {
                display: block;
              }
            }
          }
          & a:hover {
            color: rgba(0, 0, 0, 0.85);
            text-decoration: underline;
          }
          .col-wrap:first-child {
            > span {
              display: none;
            }
            & a {
              display: inline-block;
              margin: 10px 0;
              font-family: var(--font-montserrat), Arial, Helvetica, sans-serif;
              font-size: 15px;
              color: #e7131a;
            }
            @media (min-width: 976px) {
              > span {
                display: inline-block;
                font-family:
                  var(--font-montserrat), Arial, Helvetica, sans-serif;
                font-weight: bold;
                font-size: 18px;
                margin-right: 10px;
              }
              & a {
                font-size: 12px;
                text-decoration: underline;
                font-family: Arial, Helvetica, 'Helvetica Neue', sans-serif;
              }
            }
          }
          .col-wrap:nth-child(2) {
            .col li {
              padding-top: 10px;
              padding-bottom: 10px;
              & a {
                display: block;
                font-family:
                  var(--font-montserrat), Arial, Helvetica, sans-serif;
                font-size: 15px;
                font-weight: normal;
              }
            }
            & li.-title.mobile-dropdown,
            & li.-title.mobile-dropdown > a {
              cursor: pointer;
              position: relative;
              font-size: 15px;
              font-family: var(--font-montserrat), Arial, Helvetica, sans-serif;
              font-weight: bold;
            }
            @media (min-width: 976px) {
              display: flex;
              .col {
                width: 45%;
                & li {
                  padding: 0;
                  margin-bottom: 7.5px;
                  & a {
                    font-size: 14px;
                    font-weight: normal;
                  }
                }
              }
              & li.-title {
                margin-bottom: 10px;
                > a {
                  margin-bottom: 7.5px;
                }
              }
            }
            & li.-title:not(.no-dropdown) > a {
              font-size: 15px;
              font-weight: bold;
            }
          }

          @media (min-width: 976px) {
            width: 556px;
            background: white;
            .col {
              float: none;
              & ul {
                margin: 5px 0 20px;
              }
            }
          }
        }
      `}</style>
    </>
  )
}

export default MiddleMenu
