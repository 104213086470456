import { GC_DRAWER_HEADER_MEGA_MENU } from '@constants/global'
import { useChangeDrawerArr } from '@hooks/global/drawer'
import { useTracking } from 'react-tracking'

import MenuIcon from './MenuIcon'

const ShopMenu: React.FC = () => {
  const { changeDrawer } = useChangeDrawerArr()
  const { trackEvent } = useTracking()
  return (
    <div
      className="shop-menu w-[64px] legacy:w-[90px] flex justify-center items-center"
      onClick={() => {
        changeDrawer(GC_DRAWER_HEADER_MEGA_MENU)
        trackEvent({
          pageLocation: 'header',
          eventName: 'userOpenMegaMenu'
        })
      }}
      aria-label="shop menu button"
    >
      <MenuIcon />
    </div>
  )
}

export default ShopMenu
