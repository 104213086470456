import { IMAGE_PREFIX } from '@constants/media'
import { ONE_DAY } from '@gc/common-lib/constants/time'
import { withErrorBoundary } from '@gc/common-lib/hoc/withErrorBoundary'
import { useHeaderFooterData } from '@gc/common-lib/hooks/global'
import { isArrayAndNotEmpty } from '@gc/common-lib/utils/lodash'
import CountDown from '@gc/ui-components/countdown'
import Image from '@gc/ui-components/image'
import { useFormatCurrency } from '@hooks/global'
import { useChangeHeaderBottomActiveArray } from '@hooks/header'
import { get, toLower } from 'lodash'
import { useEffect, useState } from 'react'

const RightNav = () => {
  const [mounted, setMounted] = useState(false)

  const formatCurrency = useFormatCurrency('$')

  useEffect(() => {
    setMounted(true)
  }, [])

  const todayMS = new Date().getTime()
  const { menu: headerMenu } = useHeaderFooterData()
  const { changeHeaderBottomActiveArray } = useChangeHeaderBottomActiveArray()
  if (!isArrayAndNotEmpty(headerMenu)) {
    return null
  }

  const rightNavArrObj = headerMenu?.filter(
    item => item.parentMediaName === 'Drop Down Menu'
  )

  const subNavigation = get(rightNavArrObj?.[0], 'subNavigation')

  if (!subNavigation || !isArrayAndNotEmpty(subNavigation)) {
    return null
  }

  const menuNameClassMap = name => {
    return toLower(name).replace(/\s/g, '')
  }

  const dailyPickRender = (): React.ReactElement | null => {
    if (!mounted) return null

    const deals = subNavigation.find(item => item.childItemName === 'Deals')
    if (deals?.dailyPick) {
      const {
        link,
        promoEndTimestamp,
        imageSrc,
        displayName,
        todaysPrice,
        listPrice
      } = deals.dailyPick
      const leftMilliseconds = () => {
        const leftDay = (promoEndTimestamp - todayMS) / ONE_DAY
        if (leftDay > 1) {
          const msInOneDay = promoEndTimestamp - Math.floor(leftDay) * ONE_DAY
          return msInOneDay
        }
        return promoEndTimestamp
      }
      return (
        <div className="w-[265px] h-[152px] p-3 bg-[#f7f7f7] border border-[#dadbdd]">
          <div className="flex flex-row justify-between">
            <span className="text-base font-bold mb-1">Daily Pick</span>
            <span className="countDownDate text-sm text-[#1d862c]">
              <CountDown
                value={leftMilliseconds()}
                format="HH:mm:ss"
                valueStyle={{
                  fontSize: '14px',
                  fontWeight: '500',
                  color: '#1d862c',
                  lineHeight: '18px'
                }}
              />
            </span>
          </div>

          <div className="flex flex-row justify-between">
            <a
              href={link}
              className="h-[94px] pt-[5px] mr-[6px] border border-[#333]"
              title="Daily Pick"
            >
              <span className="inline-block w-[84px] h-[84px]">
                <Image
                  src={IMAGE_PREFIX + imageSrc}
                  width={84}
                  height={84}
                  alt="Daily Pick"
                />
              </span>
            </a>
            <div className="flex flex-col">
              <a
                href={link}
                className="dailyPickProdName mb-[5px] text-sm font-normal leading-[16px]"
                title={displayName}
              >
                {displayName}
              </a>
              <div className="text-base font-bold text-[#e7131a]">
                {formatCurrency(todaysPrice)}
              </div>
              <div className="text-xs font-semibold line-through">
                {formatCurrency(listPrice)}
              </div>
            </div>
          </div>
          <style global jsx>{`
            .dailyPickProdName {
              display: -webkit-box;
              overflow: hidden;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
            }
            .countDownDate .ant-statistic-content-value {
              color: #1d862c;
              font-family: var(--font-montserrat), Arial, Helvetica, sans-serif;
            }
          `}</style>
        </div>
      )
    }
    return null
  }

  const collapsibleNavigation = () => {
    const navLen = subNavigation.length
    const navs = subNavigation.map((item, index) => {
      if (index > 1 && index < navLen - 1) {
        return !item?.navigationHtml ? (
          <a
            key={item.childItemName}
            className={`block py-[5px] px-[18px] inner-collapsible-${menuNameClassMap(
              item.childItemName
            )}`}
            href={item?.childItemLink}
            title={item.childItemName}
          >
            {item.childItemName}
          </a>
        ) : (
          <div
            key={item.childItemName}
            className={`more-collapsed-menu relative py-[5px] px-[18px] inner-collapsible-${menuNameClassMap(
              item.childItemName
            )}`}
          >
            {item.childItemName}
            {item?.navigationHtml && (
              <div
                className="departments-menu-dropdown absolute top-[-10px] right-[200px] min-w-[200px] p-[10px] bg-white z-[12]"
                style={{ boxShadow: '0 0 15px rgba(0,0,0,0.15)' }}
              >
                <div
                  dangerouslySetInnerHTML={{ __html: item?.navigationHtml }}
                />
                {item.childItemName === 'Deals' && mounted && dailyPickRender()}
              </div>
            )}
          </div>
        )
      }
      return null
    })
    return navs
  }

  return (
    <div className="rightNav element-hidden h-full font-normal legacy:flex ml-auto">
      {subNavigation.map(item => {
        return (
          <div
            className={`cursor-pointer pl-[5px] mr-[10px] outer-collapsible-${menuNameClassMap(
              item.childItemName
            )}`}
            key={item.childItemName}
          >
            {!item?.navigationHtml ? (
              <a
                className={`flex h-full items-center ${
                  item.childItemName === 'Try Lessons' && 'text-[#e7131a]'
                } `}
                href={item?.childItemLink}
                title={item.childItemName}
              >
                {item.childItemName}
              </a>
            ) : (
              <button
                className={`flex h-full items-center relative ${
                  item.childItemName === 'More'
                    ? 'more-menu'
                    : 'departments-menu'
                }`}
                onMouseEnter={() =>
                  changeHeaderBottomActiveArray('save', 'right')
                }
                onMouseLeave={() =>
                  changeHeaderBottomActiveArray('del', 'right')
                }
              >
                <span className="mr-[5px]">{item.childItemName}</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12.934"
                  height="8.961"
                  className="inline-block"
                  viewBox="0 0 12.934 8.961"
                >
                  <path
                    id="icon_Arrow_RIght_sm"
                    data-name="icon Arrow RIght sm"
                    d="M0,10.116,6,5.058,0,0"
                    transform="translate(11.525 1.409) rotate(90)"
                    fill="none"
                    stroke="#333"
                    strokeLinecap="round"
                    strokeWidth="2"
                    opacity="0.997"
                  />
                </svg>
                {item?.navigationHtml && (
                  <>
                    {item.childItemName === 'More' ? (
                      <div
                        className="departments-menu-dropdown absolute top-[49px] right-0 min-w-[200px] py-[10px] bg-white z-[12]"
                        style={{ boxShadow: '0 0 15px rgba(0,0,0,0.15)' }}
                      >
                        {collapsibleNavigation()}
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item?.navigationHtml
                          }}
                        />
                      </div>
                    ) : (
                      <div
                        className="departments-menu-dropdown absolute top-[49px] min-w-[200px] p-[10px] bg-white z-[12]"
                        style={{ boxShadow: '0 0 15px rgba(0,0,0,0.15)' }}
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item?.navigationHtml
                          }}
                        />

                        {item.childItemName === 'Deals' && dailyPickRender()}
                      </div>
                    )}
                  </>
                )}
              </button>
            )}
          </div>
        )
      })}
      <style jsx global>
        {`
          .rightNav {
            .-title {
              display: block;
              font-size: 16px;
              font-family:
                var(--font-montserrat), 'Arial Black', Arial, Helvetica,
                sans-serif;
              font-weight: bold;
            }
            .-title:first-child a {
              display: inline;
              margin-left: 10px;
              font-family: Arial, Helvetica, 'Helvetica Neue', sans-serif;
              font-size: 12px;
              color: #e7131a;
              &:hover {
                color: #e7131a;
                text-decoration: underline;
              }
            }
            .departments-menu-dropdown-ol li {
              padding: 5px 13px;
            }
            .more-menu-dropdown-ol li {
              padding: 5px 18px;
            }
            .outer-collapsible-more {
              margin-right: 0;
            }
            .departments-menu-dropdown-ol,
            .more-menu-dropdown-ol {
              text-align: left;
              &:before {
                position: absolute;
                top: -29px;
                left: 17px;
                width: 0;
                height: 0;
                content: '';
                z-index: 1;
                pointer-events: none;
                border: solid transparent;
                border-width: 15px;
                border-color: transparent transparent #fff;
              }
              & a:hover {
                color: rgba(0, 0, 0, 0.85);
                text-decoration: underline;
              }
            }
            .more-menu-dropdown-ol:before {
              left: auto;
              right: 26px;
            }
            @media (max-width: 1199px) {
              .departments-menu .departments-menu-dropdown {
                right: 0;
              }
              .departments-menu-dropdown-ol:before,
              .more-menu-dropdown-ol:before {
                left: auto;
                right: 26px;
              }
            }
            .more-collapsed-menu .departments-menu-dropdown-ol:before,
            .departments-menu-dropdown {
              text-align: left;
              display: none;
            }
            .departments-menu:hover,
            .more-menu:hover,
            .more-collapsed-menu:hover {
              > .departments-menu-dropdown {
                display: block;
              }
              > span {
                color: #e7131a;
              }
            }
            .more-menu .more-collapsed-menu:before {
              position: absolute;
              left: 5px;
              top: 10px;
              display: block;
              content: '';
              height: 10px;
              width: 10px;
              background-size: 100%;
              background-image: url(/assets/icons/header/arrow-down.svg);
              background-repeat: no-repeat;
              background-position: center;
              cursor: pointer;
              transform: rotate(90deg);
            }

            .inner-collapsible-deals,
            .inner-collapsible-newarrivals,
            .inner-collapsible-beginnergear,
            .inner-collapsible-financing,
            .inner-collapsible-riffsblog {
              display: none;
            }
            @media (max-width: 1050px) {
              .inner-collapsible-deals {
                display: block;
              }
              .outer-collapsible-deals,
              .outer-collapsible-newarrivals,
              .outer-collapsible-beginnergear,
              .outer-collapsible-financing,
              .outer-collapsible-riffsblog {
                display: none;
              }
            }
            @media (max-width: 1150px) {
              .inner-collapsible-newarrivals {
                display: block;
              }
              .outer-collapsible-newarrivals,
              .outer-collapsible-beginnergear,
              .outer-collapsible-financing,
              .outer-collapsible-riffsblog {
                display: none;
              }
            }
            @media (max-width: 1200px) {
              .inner-collapsible-beginnergear {
                display: block;
              }
              .outer-collapsible-beginnergear,
              .outer-collapsible-financing,
              .outer-collapsible-riffsblog {
                display: none;
              }
            }
            @media (max-width: 1300px) {
              .inner-collapsible-financing {
                display: block;
              }
              .outer-collapsible-financing,
              .outer-collapsible-riffsblog {
                display: none;
              }
            }
            @media (max-width: 1350px) {
              .inner-collapsible-riffsblog {
                display: block;
              }
              .outer-collapsible-riffsblog {
                display: none;
              }
            }
          }
        `}
      </style>
    </div>
  )
}

export default withErrorBoundary(RightNav)
