import type { IMenu } from '@gc/common-lib/api/header'
import { isArrayAndNotEmpty } from '@gc/common-lib/utils/lodash'
import Image from '@gc/ui-components/image'
import Menu from '@gc/ui-components/menu'
import { useResponsive } from 'ahooks'
import type { MenuProps } from 'antd'
import { useContext, useEffect, useState } from 'react'

import { SubMenuContext } from './index'

type TMenuItem = Required<MenuProps>['items'][number]

const nameIconMap = {
  Financing: '/assets/icons/header/financing-card-black.svg',
  BuyaGiftCard: '/assets/icons/header/gift-card-black.svg',
  Lessons: '/assets/icons/header/lessons-black.svg',
  Repairs: '/assets/icons/header/repairs-black.svg',
  Rentals: '/assets/icons/header/rentals-black.svg',
  GCPro: '/assets/icons/header/gc-pro-black.svg',
  RiffsBlog: '/assets/icons/header/riffs-black.svg',
  Newsletter: '/assets/icons/header/newsletter-black.svg',
  Support: '/assets/icons/header/support-black.svg'
}

const BottomMenu: React.FC<IMenu> = props => {
  const responsive = useResponsive()
  const { subNavigation: bottomMenuList } = props
  const [isClientLegacy, setIsClient] = useState(false)

  const onOpenChange = useContext(SubMenuContext)

  useEffect(() => {
    setIsClient(responsive?.legacy ?? false)
  }, [])

  if (!isArrayAndNotEmpty(bottomMenuList)) return null

  const getMenuItem = bottomMenuList => {
    return bottomMenuList.map(item => {
      const { childItemName, childItemLink, navigationHtml, childId, article } =
        item
      const removeNameSpace = childItemName.replace(/\s/g, '')
      const normalTitle = (
        <span className="pl-[10px] font-normal">{childItemName}</span>
      )
      const linkTitle = (
        <a className="block" href={childItemLink}>
          {normalTitle}
        </a>
      )
      return {
        id: `ant-submenu-${childId}`,
        key: childId,
        children: navigationHtml
          ? [
              {
                key: childId,
                label: (
                  <>
                    <div
                      dangerouslySetInnerHTML={{ __html: navigationHtml }}
                      suppressHydrationWarning
                    />
                    {isArrayAndNotEmpty(article) && (
                      <div className="py-[10px]">
                        {article?.map((a, index) => {
                          return (
                            <div
                              className={`w-[255px] ${
                                index !== 0 && 'mt-[24px]'
                              }`}
                              key={a.title + index}
                            >
                              <a
                                className="block w-[255px] h-[134px] mb-3"
                                href={a?.['seo-url']}
                              >
                                <Image
                                  src={a.image}
                                  width={255}
                                  height={134}
                                  alt={a.title}
                                />
                              </a>
                              <a
                                href={a?.['seo-url']}
                                className="block w-[255px] text-[15px] legacy:text-sm font-normal  hover:underline hover:text-[#333]"
                              >
                                {a.title}
                              </a>
                            </div>
                          )
                        })}
                      </div>
                    )}
                  </>
                ),
                type: 'group'
              }
            ]
          : undefined,
        icon: nameIconMap?.[removeNameSpace] ? (
          <Image
            src={nameIconMap?.[removeNameSpace]}
            alt={childItemName}
            width={22}
            height={22}
          />
        ) : undefined,
        label:
          item?.childItemLink && (isClientLegacy || !navigationHtml)
            ? linkTitle
            : normalTitle
      } as TMenuItem
    })
  }

  const bottomMenuArr = getMenuItem(bottomMenuList)

  return isArrayAndNotEmpty(bottomMenuArr) ? (
    <>
      <div
        className="bottom-menu bg-[#f1f1f2] p-[10px] mt-[10px] pb-8"
        suppressHydrationWarning
      >
        {isClientLegacy ? (
          <Menu
            className="header-bottom-menu hover:bg-[#f1f1f2]"
            mode="vertical"
            items={bottomMenuArr}
            onOpenChange={onOpenChange}
          />
        ) : (
          <Menu
            className="header-bottom-menu hover:bg-[#f1f1f2]"
            mode="inline"
            items={bottomMenuArr}
          />
        )}
      </div>
      <style jsx global>{`
        .header-bottom-html .megamenu-inner-wrap {
          @media (min-width: 976px) {
            width: 255px;
            background: white;
          }
        }
        .bottom-menu {
          .ant-menu-light .ant-menu-submenu-active,
          .ant-menu-light .ant-menu-submenu-title:hover,
          .ant-menu-item a:hover {
            color: rgba(0, 0, 0, 0.85);
          }
          .ant-menu,
          .ant-menu-item:active,
          .ant-menu-submenu-title:active {
            background: #f1f1f2;
          }
          .ant-menu-title-content > span {
            text-indent: 10px;
          }
          .ant-menu-title-content > a {
            height: 42px;
          }
          .ant-menu-title-content > a:hover {
            color: rgba(0, 0, 0, 0.85);
          }
          .ant-menu-vertical .ant-menu-submenu-title,
          .ant-menu-vertical .ant-menu-item {
            padding: 0;
          }
          .ant-menu-vertical .ant-menu-submenu-title,
          .ant-menu-vertical > .ant-menu-item {
            display: flex;
            align-items: center;
            line-height: 42px;
            font-size: 14px;
          }
          .ant-menu-vertical > .ant-menu-item {
            margin: 0;
          }
        }
        @media (max-width: 975.9px) {
          .bottom-menu .ant-menu-item-group a {
            padding-left: 10px;
          }
        }
      `}</style>
    </>
  ) : null
}

export default BottomMenu
