import SearchInput from '@bc/algolia-plp/header/header-search/SearchInput'
import LiveHelp from '@bc/algolia-plp/header/live-help'
import MiniCart from '@bc/algolia-plp/header/mini-cart'
import MegaMenuModal from '@bc/algolia-plp/header/modal/mega-menu-modal'
import PromoMessage from '@bc/algolia-plp/header/promo'
import RightNav from '@bc/algolia-plp/header/right-navigation'
import ShopLogin from '@bc/algolia-plp/header/shop-login'
import ShopMenu from '@bc/algolia-plp/header/shop-menu'
import { ThirdPartJSInit } from '@bc/global/ThirdPartJSInit'
import Image from '@gc/ui-components/image'
import dynamic from 'next/dynamic'
import { useState } from 'react'

const HeaderStoreAndDelivery = dynamic(
  () => import('@bc/algolia-plp/header/header-store-delivery'),
  { ssr: false }
)

const HeaderLayout: React.FC = () => {
  const [showSearch, setShowSearch] = useState<boolean>(false)

  return (
    <>
      <ThirdPartJSInit />
      <div className="header-layout print:hidden">
        <PromoMessage />

        <div className="main items-center border-b border-borderLightColor2">
          <div className="flex h-[58px] legacy:h-[88px] max-w-[1500px] legacy:w-[calc(100%-40px)] mx-auto relative">
            <div className="flex flex-row grow legacy:!flex-none">
              <div className="w-[92px] legacy:w-[139px] h-[58px] legacy:h-[88px] bg-primaryColor">
                <a href="/">
                  <Image
                    src="/assets/icons/header/gc-logo-white.svg"
                    alt="Guitar Center logo"
                    style={{ width: '100%', height: 'auto' }}
                    width={139}
                    height={88}
                    priority={true}
                  />
                </a>
              </div>
            </div>
            <ShopMenu />
            <SearchInput isOpenSearch={showSearch} showSearch={setShowSearch} />
            <LiveHelp />
            <ShopLogin />
            <MiniCart />
          </div>
        </div>

        {/* mobile search input space */}
        {showSearch && <div className="h-12 legacy:h-0" />}

        <div className="h-[50px] justify-between bg-[#f4f4f4] border-b border-borderMoreLightColor">
          <div className="flex max-w-[1500px] h-full items-center justify-between mx-auto px-5 legacy:pl-11">
            <HeaderStoreAndDelivery />
            <RightNav />
          </div>
        </div>
        <nav id="gc_mainNav" />
        <MegaMenuModal />
      </div>
    </>
  )
}

export default HeaderLayout
