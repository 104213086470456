import { GC_DRAWER_HEADER_MEGA_MENU } from '@constants/global'
import { QUERY_KEY_SEARCH_TYPE_AHEAD } from '@constants/query/header'
import type { IMenu } from '@gc/common-lib/api/header'
import { typeAheadSearchApi } from '@gc/common-lib/api/header'
import { useUserLoginStatus } from '@gc/common-lib/hooks/userLogin'
import { isArrayAndNotEmpty } from '@gc/common-lib/utils/lodash'
import { specifyImageSize } from '@gc/common-lib/utils/string'
import Image from '@gc/ui-components/image'
import Menu from '@gc/ui-components/menu'
import Rate from '@gc/ui-components/rate'
import type { RootState } from '@store/index'
import { useQuery } from '@tanstack/react-query'
import { useLocalStorageState, useResponsive } from 'ahooks'
import type { MenuProps } from 'antd'
import { includes } from 'lodash'
import { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { SubMenuContext } from './index'

const TopMenu: React.FC<IMenu> = props => {
  const { subNavigation: topMenu } = props
  const responsive = useResponsive()
  const [openKeys, setOpenKeys] = useState([] as string[])
  const { isUserLogin } = useUserLoginStatus()
  const [recentItems = []] = useLocalStorageState<string[] | undefined>(
    'recentItems'
  )
  const onOpenChange = useContext(SubMenuContext)
  const [isClientLegacy, setIsClient] = useState(false)

  useEffect(() => {
    setIsClient(responsive?.legacy ?? false)
  }, [])

  const drawerArray = useSelector(
    (state: RootState) => state.global.drawerArray
  )

  const { data: searchResult } = useQuery({
    queryKey: [QUERY_KEY_SEARCH_TYPE_AHEAD, { searchValue: '', recentItems }],
    queryFn: () => {
      return typeAheadSearchApi({
        searchValue: '',
        recentItems: recentItems.join(',')
      })
    },
    enabled: drawerArray[0] === GC_DRAWER_HEADER_MEGA_MENU
  })
  let recentlyViewedItemArr = null as any
  if (searchResult && isArrayAndNotEmpty(searchResult?.recently)) {
    recentlyViewedItemArr = (
      <div className="flyout-menu-inner-wrap recently-viewed max-w-[680px] w-[100%]">
        <span className="inline-block pl-0 mr-[10px] text-[18px] font-bold legacy:mb-4">
          Recently Viewed
        </span>
        <div className="flex flex-wrap pt-[5px]">
          {searchResult.recently.map(item => (
            <div
              key={item.imgId}
              className="flex flex-row flex-wrap mr-[15px] mb-[40px]"
            >
              <a
                href={`${item.linkUrl}${
                  item.linkUrl.includes('?') ? '&' : '?'
                }pdptypeAheadSearchInput=&tNtt=&tAt=item&tAv=${item.name}`}
                data-name={item.name}
                className="flex w-[90px] h-[96px] legacy:w-[106px] legacy:h-[118px] mr-[5px] items-center justify-center border border-textNormalColor"
              >
                <span className="inline-block w-[85px] h-[85px] legacy:w-[100px] legacy:h-[100px]">
                  <Image
                    src={
                      responsive.legacy
                        ? specifyImageSize(item.imgUrl, 100)
                        : specifyImageSize(item.imgUrl, 85)
                    }
                    alt="search production"
                    style={{ width: '100%', height: 'auto' }}
                    width={responsive.legacy ? 100 : 85}
                    height={responsive.legacy ? 100 : 85}
                  />
                </span>
              </a>
              <div className="flex flex-col w-[152px]">
                <p className="product-title">
                  <a
                    href={`${item.linkUrl}${
                      item.linkUrl.includes('?') ? '&' : '?'
                    }pdptypeAheadSearchInput=&tNtt=&tAt=item&tAv=${item.name}`}
                    className="font-bold text-xs legacy:font-semibold legacy:text-[13px] hover:underline hover:text-[#333]"
                  >
                    {item.name}
                  </a>
                </p>
                <a
                  href={`${item.linkUrl}${
                    item.linkUrl.includes('?') ? '&' : '?'
                  }pdptypeAheadSearchInput=&tNtt=&tAt=item&tAv=${item.name}`}
                  className="inline-block cursor-pointer h-6 mt-[6px] leading-[14px]"
                >
                  <Rate rating={item.rating || 0} className="leading-[14px]" />
                </a>
                <p className="m-0 text-base font-bold leading-[19px]">
                  {/* price could be string, need to convert to number */}
                  {item.price && item.price !== '' ? (
                    <span className="font-bold">
                      {item.price.toString().replace(/&nbsp;/g, ' ')}
                    </span>
                  ) : (
                    <span className="text-xs font-bold">Contact for Price</span>
                  )}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  }

  if (!isArrayAndNotEmpty(topMenu)) return null

  const linkTitle = (childItemLink, childItemName) => (
    <a
      href={childItemLink}
      className="block h-[42px] leading-[42px] text-[15px] hover:text-[rgba(0,0,0,0.85)]"
    >
      {childItemName}
    </a>
  )

  const getMenuItem = topMenu => {
    const menuItems: MenuProps['items'] = []
    menuItems.push({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      id: 'ant-submenu-track-order',
      key: 'trackOrder',
      label: linkTitle('/trackorder', 'Track Order')
    })

    if (isUserLogin) {
      menuItems.push({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        id: 'ant-submenu-buy-it-again',
        key: 'buyItAgain',
        label: linkTitle('/myaccount/order/history', 'Buy It Again')
      })

      menuItems.push({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        id: 'ant-submenu-wishlist',
        key: 'wishList',
        label: linkTitle('/wishlists', 'Wish List')
      })
    }

    if (recentlyViewedItemArr !== null) {
      menuItems.push({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        id: 'ant-submenu-recently-viewed',
        key: 'recently-viewed',
        label: 'Recently Viewed',
        children: [
          {
            key: 'recentlyViewedChild',
            label: recentlyViewedItemArr,
            type: 'group'
          }
        ]
      })
    }

    const topMenuItems = topMenu.map(item => {
      const { childItemName, childItemLink, navigationHtml, childId } = item
      return {
        id: `ant-submenu-${childId}`,
        key: childId,
        children: navigationHtml
          ? [
              {
                key: childId,
                label: (
                  <div
                    dangerouslySetInnerHTML={{ __html: navigationHtml }}
                    suppressHydrationWarning
                  />
                ),
                type: 'group'
              }
            ]
          : undefined,
        label:
          item?.childItemLink && (isClientLegacy || !navigationHtml)
            ? linkTitle(childItemLink, childItemName)
            : childItemName
      }
    })

    const menuItemsNew = [...menuItems, ...topMenuItems]
    return menuItemsNew
  }

  const topMenuArr = getMenuItem(topMenu)

  const openChangeEvent = items => {
    const latestOpenKey = items.find(key => !includes(openKeys, key))
    setOpenKeys(latestOpenKey ? [latestOpenKey] : [])
  }

  return (
    <>
      {isArrayAndNotEmpty(topMenuArr) && (
        <div className="top-menu">
          {isClientLegacy ? (
            <Menu
              className="header-top-menu"
              mode="vertical"
              items={topMenuArr}
              onOpenChange={onOpenChange}
            />
          ) : (
            <Menu
              className="header-top-menu"
              mode="inline"
              items={topMenuArr}
              openKeys={openKeys}
              onOpenChange={openChangeEvent}
            />
          )}
        </div>
      )}

      <style jsx global>{`
        .header-top-menu,
        .header-middle-menu,
        .header-bottom-menu {
          > li,
          > li .ant-menu-submenu-title {
            margin: 0px !important;
            height: auto !important;
            line-height: 42px !important;
          }
        }
        .header-top-menu > li.ant-menu-item:first-child {
          margin-top: 4px !important;
        }
        .flyout-menu-inner-wrap .product-title {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          margin: 4px 0 0;
          line-height: 15px;
        }
        .ant-menu-submenu .flyout-menu-inner-wrap .ant-rate .ant-rate-star,
        .top-menu .ant-rate .ant-rate-star {
          line-height: 14px;
        }
        .top-menu a,
        .ant-menu-title-content {
          display: block;
          font-family: var(--font-montserrat), Arial, Helvetica, sans-serif;
          font-size: 15px;
          color: #333;
        }
        .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
          background: transparent;
        }
        .ant-menu-submenu .ant-menu-item-group-title {
          color: #333;
        }
        .ant-menu-inline
          .ant-menu-sub.ant-menu-inline
          .ant-menu-item-group-title {
          padding: 0;
        }
        .ant-menu-vertical {
          .ant-menu-item-group-title {
            padding: 0;
          }
          .ant-menu-submenu-title,
          .ant-menu-item {
            padding: 0 0 0 10px;
          }
          .ant-menu-item:active,
          .ant-menu-item.ant-menu-item-active:hover,
          .ant-menu-submenu-title:active {
            background: #f1f1f2;
          }
        }
        .ant-menu-item-selected a,
        .ant-menu-submenu:hover
          > .ant-menu-submenu-title
          > .ant-menu-submenu-arrow {
          color: #333;
        }
        .ant-menu-submenu-inline > ul.ant-menu-sub.ant-menu-inline {
          padding-left: 40px;
          padding-bottom: 10px;
        }
        .ant-menu-submenu {
          &-active .ant-menu-submenu-title,
          .ant-menu-sub.ant-menu-inline {
            background: #f1f1f2 !important;
          }
          .ant-menu-submenu-arrow {
            right: 25px;
          }
          .ant-menu-submenu-arrow::before,
          .ant-menu-submenu-arrow::after {
            width: 12px;
            height: 1px;
          }
          .ant-menu-submenu-arrow::before {
            transform: rotate(45deg) translateY(-6px);
          }
          .ant-menu-submenu-arrow::after {
            transform: rotate(-45deg) translateY(6px);
          }
          &.ant-menu-submenu-open.ant-menu-submenu-inline
            > .ant-menu-submenu-title
            > .ant-menu-submenu-arrow::before {
            transform: rotate(45deg) translateX(6px);
          }
          &.ant-menu-submenu-open.ant-menu-submenu-inline
            > .ant-menu-submenu-title
            > .ant-menu-submenu-arrow::after {
            transform: rotate(-45deg) translateX(-6px);
          }
          .ant-menu-sub,
          .ant-menu-sub.ant-menu-inline > .ant-menu-item {
            height: auto;
          }
          .ant-menu-item a:hover {
            color: #40a9ff;
          }
          .flyout-menu-inner-wrap {
            @media (min-width: 976px) {
              width: 286px;
              background: white;
              &.recently-viewed {
                width: 100%;
              }
            }
            & li {
              line-height: 21px;
              > a {
                display: block;
                padding: 10px 0;
                font-family:
                  var(--font-montserrat), Arial, Helvetica, sans-serif;
                &:hover {
                  text-decoration: underline;
                  color: #333;
                }
                @media (min-width: 976px) {
                  padding: 5px 0;
                }
              }
              &.-title > a {
                padding-top: 15px;
                font-family:
                  var(--font-montserrat), Arial, Helvetica, sans-serif;
                font-weight: bold;
                font-size: 18px;
              }
            }
            > span {
              display: inline-block;
              margin: 0 10px 10px 0;
              font-family: var(--font-montserrat), Arial, Helvetica, sans-serif;
              font-weight: bold;
              line-height: 27px;
              font-size: 18px;
              @media (max-width: 975.9px) {
                display: none;
              }
            }
            > a {
              padding-top: 10px;
              line-height: 27px;
              color: #e7131a;
              &:hover {
                text-decoration: underline;
              }
              @media (min-width: 976px) {
                padding-top: 0;
                font-size: 12px;
                text-decoration: underline;
                font-family: Arial, Helvetica, 'Helvetica Neue', sans-serif;
              }
            }
          }
          .ant-menu-item,
          .ant-menu-submenu-title {
            margin: 0;
            &:active {
              background: transparent;
            }
          }
          .ant-menu-vertical {
            padding: 21px 24px;
            & > .ant-menu-item {
              height: auto;
              padding: 0;
            }
          }
        }
        .ant-menu-inline.ant-menu-root {
          .col {
            float: none;
          }

          > .ant-menu-item,
          .ant-menu-submenu-title {
            padding-left: 10px !important;
          }
        }
      `}</style>
    </>
  )
}

export default TopMenu
