import { GC_DRAWER_HEADER_MINI_CART } from '@constants/global'
import { withErrorBoundary } from '@gc/common-lib/hoc/withErrorBoundary'
import { useHeaderUserInfo } from '@gc/common-lib/hooks/header'
import Image from '@gc/ui-components/image'
import { useChangeDrawerArr } from '@hooks/global/drawer'
import dynamic from 'next/dynamic'

const MiniCartModal = dynamic(() => import('../modal/MiniCartModal'), {
  ssr: false
})

const MiniCart: React.FC = () => {
  const { changeDrawer, judgeIsDisplayDrawer } = useChangeDrawerArr()
  const { lineCount = -1 } = useHeaderUserInfo()
  return (
    <>
      <button
        onClick={() => {
          changeDrawer(GC_DRAWER_HEADER_MINI_CART)
        }}
        className="MiniCart flex flex-col justify-center w-[64px] legacy:w-[68px] h-full items-center text-sm text-center cursor-pointer"
      >
        <div className="miniCartIcon relative w-8 h-8 mx-auto">
          <Image
            alt="mini cart icon"
            src="/assets/icons/header/shopping-cart.svg"
            className="mx-auto"
            width={32}
            height={32}
            priority={true}
          />
          {lineCount > -1 && (
            <span className="miniCartTotal absolute top-[-2px] legacy:top-[-5px] right-[-8px] legacy:right-[-10px] w-4 h-4 legacy:w-5 legacy:h-5 leading-4 legacy:leading-5 bg-[#333] legacy:!bg-[#000] text-white text-xs rounded-[18px]">
              {lineCount}
            </span>
          )}
        </div>
        <span className="pt-[2px] font-normal element-hidden legacy:block">
          Cart
        </span>
      </button>
      {judgeIsDisplayDrawer(GC_DRAWER_HEADER_MINI_CART) ? (
        <MiniCartModal />
      ) : null}
    </>
  )
}

export default withErrorBoundary(MiniCart)
