const MenuIcon: React.FC = () => {
  return (
    <button className="cursor-pointer" aria-label="SHOP">
      <div className="w-8 legacy:w-10 h-[3px] bg-primaryColor legacy:!bg-textNormalColor mb-[6px] legacy:mb-[5px]" />
      <div className="w-8 legacy:w-10 h-[3px] bg-primaryColor legacy:!bg-textNormalColor mb-[6px] legacy:mb-[5px]" />
      <div className="w-8 legacy:w-10 h-[3px] bg-primaryColor legacy:!bg-textNormalColor mb-[0] legacy:mb-[2px]" />
      <span className="font-bold text-sm element-hidden legacy:inline">
        SHOP
      </span>
    </button>
  )
}

export default MenuIcon
