import { GC_DRAWER_HEADER_SIGN_IN } from '@constants/global'
import { withErrorBoundary } from '@gc/common-lib/hoc/withErrorBoundary'
import { useHeaderFooterData } from '@gc/common-lib/hooks/global'
import { useUserLoginStatus } from '@gc/common-lib/hooks/userLogin'
import { eventEmitter, SIGN_IN_EVENT } from '@gc/common-lib/utils/eventEmitter'
import Image from '@gc/ui-components/image'
import { useSignModal } from '@hooks/global'
import { useChangeDrawerArr } from '@hooks/global/drawer'
import { defaultActionParams, save } from '@store/global'
import type { RootState } from '@store/index'
import { useStoreInstance } from '@store/index'
import { Steps } from '@typings/global'
import { useLatest } from 'ahooks'
import { assign, capitalize } from 'lodash'
import dynamic from 'next/dynamic'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

const WishlistConfirmModal = dynamic(
  () => import('@bc/global/sign-in-modal/WishlistConfirmModal'),
  {
    ssr: false
  }
)

const SignInModal = dynamic(() => import('@bc/global/sign-in-modal'), {
  ssr: false
})

const ShopLogin: React.FC = () => {
  const { changeDrawer, judgeIsDisplayDrawer } = useChangeDrawerArr()
  const { isUserLogin, userFirstName, isLoading } = useUserLoginStatus()
  const {
    steps,
    setSteps,
    activeStep,
    setActiveStep,
    changeStep,
    resetStep,
    actionParams,
    setActionParams
  } = useSignModal()

  const isLoginRef = useLatest(isUserLogin)
  const store = useStoreInstance()
  const [showWishlistConfirmModal, setShowWishlistConfirmModal] =
    useState(false)
  const selectedWishlist = useSelector(
    (state: RootState) => state.global.selectedWishlist
  )

  let displayText: string | null
  if (isLoading) {
    displayText = null
  } else if (isUserLogin) {
    displayText = `Hi, ${capitalize(userFirstName)}`
  } else {
    displayText = 'Sign In'
  }
  const data = useHeaderFooterData()
  const storeConfig = {
    ...data.storeConfig?.value,
    recaptchaEnable: data.recaptchaEnable
  }

  useEffect(() => {
    const listener = (params = defaultActionParams) => {
      if (
        (isLoginRef.current && params?.targetStep === Steps.SignIn) ||
        params?.isSessionTimeout
      ) {
        return
      }
      const activeStep = !isLoginRef.current ? Steps.SignIn : params?.targetStep
      setActionParams(assign({}, defaultActionParams, params))
      setSteps([activeStep ?? Steps.SignIn])
      setActiveStep(activeStep)

      changeDrawer(GC_DRAWER_HEADER_SIGN_IN)
    }
    eventEmitter.on(SIGN_IN_EVENT, listener)
    return () => {
      eventEmitter.off(SIGN_IN_EVENT, listener)
    }
  }, [])

  return (
    <>
      <button
        className="ShopLogin flex flex-col justify-center w-[85px] h-full text-sm text-center cursor-pointer md:relative"
        onClick={() => {
          changeDrawer(GC_DRAWER_HEADER_SIGN_IN)
        }}
      >
        <span className="ShopLoginIcon inline-block w-7 h-7 mx-auto md:absolute md:top-[18.4px] md:left-0 right-0">
          <Image
            alt="shop login icon"
            src="/assets/icons/header/account-red.svg"
            className="mx-auto"
            width={28}
            height={28}
            priority={true}
          />
        </span>
        <span
          title={displayText || 'Sign In'}
          className="ShopLoginText pt-[3px] md:mt-[29.5px] font-normal mx-auto w-full max-h-[40px] whitespace-nowrap overflow-hidden text-ellipsis"
        >
          {displayText || 'Sign In'}
        </span>
      </button>
      {judgeIsDisplayDrawer(GC_DRAWER_HEADER_SIGN_IN) ? (
        <SignInModal
          isLogin={isUserLogin}
          userFirstName={userFirstName}
          messageContent={data.messageContent?.contentItem ?? {}}
          storeConfig={storeConfig}
          steps={steps}
          setSteps={setSteps}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          changeStep={changeStep}
          resetStep={resetStep}
          actionParams={actionParams}
          setActionParams={setActionParams}
          setShowWishlistConfirmModal={setShowWishlistConfirmModal}
        />
      ) : null}

      {showWishlistConfirmModal ? (
        <WishlistConfirmModal
          product={actionParams.options?.product}
          onCancel={() => {
            setShowWishlistConfirmModal(false)
            setSteps([Steps.SignIn])
          }}
          onSuccess={() => {
            store.dispatch(save({ selectedWishlist: undefined }))
            setShowWishlistConfirmModal(false)
            actionParams?.options?.successCallback?.('added')
            setActionParams(defaultActionParams)
            setSteps([Steps.SignIn])
          }}
          selectedWishlist={selectedWishlist}
        />
      ) : null}

      <style jsx global>{`
        @media (max-width: 975.9px) {
          .ShopLogin {
            width: 64px !important;
            .ShopLoginText {
              display: none;
            }
            .ShopLoginIcon {
              top: 14.4px;
            }
          }
        }
      `}</style>
    </>
  )
}

export default withErrorBoundary(ShopLogin)
