import { GC_DRAWER_HEADER_MEGA_MENU } from '@constants/global'
import { withErrorBoundary } from '@gc/common-lib/hoc/withErrorBoundary'
import { useHeaderFooterData } from '@gc/common-lib/hooks/global'
import { isArrayAndNotEmpty } from '@gc/common-lib/utils/lodash'
import { useChangeDrawerArr } from '@hooks/global/drawer'
import dynamic from 'next/dynamic'
import { createContext, useCallback, useState } from 'react'

import ModalSignInTextTitle from '../ModalSignInTextTitle'
import BottomMenu from './BottomMenu'
import MiddleMenu from './MiddleMenu'
import TopMenu from './TopMenu'

const DrawerLayout = dynamic(() => import('@uc/basic/drawer/DrawerLayout'), {
  ssr: false
})
export const SubMenuContext = createContext<(value: string[]) => void>(
  null as any
)

const MegaMenuModal: React.FC = () => {
  const [subMenuHeight, setSubMenuHeight] = useState<number>(0)
  const { menu: data } = useHeaderFooterData()
  const { judgeIsDisplayDrawer } = useChangeDrawerArr()

  const onOpenChange = useCallback(id => {
    const currentSelectItem = document.getElementById(`ant-submenu-${id?.[0]}`)
    const rect = currentSelectItem?.getBoundingClientRect()
    if (rect) {
      const eleHeight = window.innerHeight - rect.top
      setSubMenuHeight(eleHeight)
    }
  }, [])

  if (!isArrayAndNotEmpty(data)) {
    return null
  }

  const topMenuArrObj = data?.filter(
    item => item.parentMediaName === 'Top Menu'
  )

  const middleMenuArrObj = data?.filter(
    item => item.parentMediaName === 'Shop By Category'
  )

  const bottomMenuArrObj = data?.filter(
    item => item.parentMediaName === 'Bottom Menu'
  )

  const megaMenuModalInnerContent = () => {
    return (
      <div className="mega-menu-inner-content">
        {topMenuArrObj && isArrayAndNotEmpty(topMenuArrObj) && (
          <TopMenu {...topMenuArrObj?.[0]} />
        )}
        {middleMenuArrObj && isArrayAndNotEmpty(middleMenuArrObj) && (
          <MiddleMenu {...middleMenuArrObj?.[0]} />
        )}
        {bottomMenuArrObj && isArrayAndNotEmpty(bottomMenuArrObj) && (
          <BottomMenu {...bottomMenuArrObj?.[0]} />
        )}
      </div>
    )
  }
  return (
    <>
      <div className="hidden clear-both">{megaMenuModalInnerContent()}</div>
      <SubMenuContext.Provider value={onOpenChange}>
        {judgeIsDisplayDrawer(GC_DRAWER_HEADER_MEGA_MENU) ? (
          <DrawerLayout
            name={GC_DRAWER_HEADER_MEGA_MENU}
            width={305}
            placement="left"
            title={<ModalSignInTextTitle />}
            destroyOnClose={false}
            overlayTheme="dark"
          >
            {megaMenuModalInnerContent()}
          </DrawerLayout>
        ) : null}

        <style jsx global>{`
          body {
            .ant-menu-submenu-popup {
              top: auto !important;
              left: 305px !important;
              bottom: 0vh !important;
            }
            .mega-menu-inner-content .ant-menu-light > li,
            .mega-menu-inner-content
              .ant-menu-light
              > li
              .ant-menu-submenu-title {
              border-radius: 0 !important;
              width: 100% !important;
              &:hover,
              &:active {
                background-color: #f1f1f2 !important;
              }
            }
            .ant-menu-submenu-popup .ant-menu-vertical.ant-menu-sub {
              height: fit-content !important;
              min-height: ${subMenuHeight}px;
              max-height: 100vh !important;
              @media (min-width: 976px) {
                padding: 21px 24px;
              }
            }
            .ant-menu-submenu-placement-rightTop,
            .ant-menu-submenu-placement-rightBottom {
              padding: 0;
            }
            .ant-menu-light.ant-menu-submenu .ant-menu-item-group-title {
              color: #333;
            }
            .ant-menu-submenu-popup > .ant-menu {
              border-radius: 2px;
            }
          }
        `}</style>
      </SubMenuContext.Provider>
    </>
  )
}

export default withErrorBoundary(MegaMenuModal)
