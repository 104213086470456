import { GC_DRAWER_HEADER_LIVE_HELP } from '@constants/global'
import { GC_HEADER_LIVE_HELP_PHONE_NUMBER_DEFAULT } from '@gc/common-lib/constants/global'
import { withErrorBoundary } from '@gc/common-lib/hoc/withErrorBoundary'
import { useHeaderUserInfo } from '@gc/common-lib/hooks/header'
import Image from '@gc/ui-components/image'
import { useChangeDrawerArr } from '@hooks/global/drawer'
import dynamic from 'next/dynamic'

const LiveHelpModal = dynamic(() => import('../modal/LiveHelpModal'), {
  ssr: false
})

const LiveHelp: React.FC = () => {
  const { callCenterNumber = GC_HEADER_LIVE_HELP_PHONE_NUMBER_DEFAULT } =
    useHeaderUserInfo()
  const { changeDrawer, judgeIsDisplayDrawer } = useChangeDrawerArr()
  return (
    <>
      <button
        className="pl-[18px] pr-1 items-center cursor-pointer element-hidden legacy:flex"
        onClick={() => changeDrawer('liveHelp')}
      >
        <div className="w-[50px] h-[50px] mr-[5px]">
          <Image
            src="/assets/icons/header/liveHelpIcon.webp"
            alt="liveHelpIcon"
            className="rounded-[50px]"
            style={{ width: '100%', height: 'auto' }}
            width={50}
            height={50}
            priority={true}
          />
        </div>
        <div className="mr-[10px]">
          <div className="live-help-text mb-1 text-left text-textNormalColor  font-bold">
            Live Help
          </div>
          <div className="live-help-phone font-normal">{callCenterNumber}</div>
        </div>
        <span className="help-arrow mr-[10px]">&gt;</span>
      </button>
      {judgeIsDisplayDrawer(GC_DRAWER_HEADER_LIVE_HELP) ? (
        <LiveHelpModal />
      ) : null}
    </>
  )
}

export default withErrorBoundary(LiveHelp)
