import {
  GC_DRAWER_HEADER_MEGA_MENU,
  GC_DRAWER_HEADER_SIGN_IN
} from '@constants/global'
import { useUserLoginStatus } from '@gc/common-lib/hooks/userLogin'
import { useChangeDrawerArr } from '@hooks/global/drawer'

const ModalSignInTextTitle = () => {
  const { isUserLogin, userFirstName } = useUserLoginStatus()
  const { changeDrawer } = useChangeDrawerArr()
  return (
    <p className="text-white align-top inline-block leading-[0]">
      <span className="text-[18px] leading-6 md:text-xl md:leading-[26px] font-bold align-text-bottom">
        {isUserLogin ? (
          <span className="flex items-center">
            <a
              href="/myaccount/overview"
              className="text-white hover:underline"
            >
              Hi, {userFirstName}
            </a>
          </span>
        ) : (
          <span
            onClick={() => {
              changeDrawer(GC_DRAWER_HEADER_MEGA_MENU)
              changeDrawer(GC_DRAWER_HEADER_SIGN_IN)
            }}
            className="cursor-pointer text-white hover:underline"
          >
            Sign in
          </span>
        )}
      </span>
    </p>
  )
}

export default ModalSignInTextTitle
