import TypeAhead from '@bc/algolia-plp/header/type-ahead'
import { ALGOLIA_QUERY_LENGTH_LIMIT } from '@constants/algolia-plp/configure'
import { withErrorBoundary } from '@gc/common-lib/hoc/withErrorBoundary'
import { useDebounce, useLocalStorageState } from 'ahooks'
import classnames from 'classnames'
import { filter, slice, trim } from 'lodash'
import { useEffect, useState } from 'react'

import SearchIcon from './SearchIcon'

interface SearchInputProps {
  isOpenSearch: boolean
  showSearch: (show: boolean) => void
}
const SearchInput: React.FC<SearchInputProps> = ({
  isOpenSearch,
  showSearch
}) => {
  const [placeHolderContent, setPlaceHolderContent] =
    useState<string>('Find your sound')
  const [isFocusSearchInput, setIsFocusSearchInput] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>('')
  const [recentSearchValues = [], setRecentSearchValues] = useLocalStorageState<
    string[] | undefined
  >('recentSearchValues')

  const debouncedSearchValue = useDebounce(searchValue, { wait: 500 })

  const handleSearch = () => {
    const arr = filter(recentSearchValues, item => item !== searchValue)
    arr.unshift(searchValue)
    setRecentSearchValues(slice(arr, 0, 10))
    const encodeValue = encodeURIComponent(trim(searchValue))
    const path = `/search?typeAheadSuggestion=true&fromRecentHistory=false&Ntt=${encodeValue}`
    window.location.href = path
  }
  useEffect(() => {
    setSearchValue(sessionStorage.getItem('searchQuery') ?? '')
  }, [])

  return (
    <div className="header-search-wrapper legacy:ml-[22px] h-full items-center legacy:flex-1 flex">
      <div
        className={classnames('mask', {
          'bg-[rgba(0,0,0,0.6)] legacy:!bg-[rgba(0,0,0,0.8)] fixed w-full h-full top-0 left-0 z-[1001]':
            isOpenSearch
        })}
        onClick={() => {
          showSearch(false)
          setIsFocusSearchInput(false)
        }}
      />
      <div
        className={classnames(
          'h-[48px] w-full absolute legacy:relative left-0 bottom-[-48px] legacy:bottom-0',
          {
            'z-[1002]': isOpenSearch,
            'element-hidden legacy:block': !isOpenSearch
          }
        )}
      >
        <label htmlFor="header-search-input" className="hidden">
          search
        </label>
        <input
          type="text"
          maxLength={ALGOLIA_QUERY_LENGTH_LIMIT}
          id="header-search-input"
          className="border bg-white outline-[0px] h-full w-full text-[15px] rounded-[5px] pl-2 legacy:pl-6 placeholder:text-textNormalColor pr-[56px]"
          placeholder={placeHolderContent}
          value={searchValue}
          autoComplete="off"
          onChange={e => {
            if (e?.target?.value === '') {
              setSearchValue(' ')
            } else {
              setSearchValue(e?.target?.value)
            }
          }}
          onKeyUp={e => {
            if (e.key === 'Enter' && searchValue) {
              handleSearch()
            }
          }}
          onFocus={() => {
            setPlaceHolderContent('')
            showSearch(true)
            setIsFocusSearchInput(true)
          }}
          onBlur={() => {
            setPlaceHolderContent('Find your sound')
          }}
        />
        <button
          className="absolute right-0 top-0 w-[56px] h-full flex items-center justify-center cursor-pointer"
          onClick={() => {
            if (searchValue) {
              handleSearch()
            }
          }}
        >
          <span className="hidden">search</span>
          <SearchIcon />
        </button>
        {isFocusSearchInput ? (
          <TypeAhead
            debouncedSearchValue={debouncedSearchValue}
            recentSearchValues={recentSearchValues}
            changeRecentSearchValues={setRecentSearchValues}
          />
        ) : null}
      </div>
      <button
        className="w-[64px] h-full items-center justify-center flex legacy:hidden cursor-pointer"
        onClick={() => showSearch(true)}
        aria-label="search button"
      >
        <span className="hidden">search</span>
        <SearchIcon isPrimaryColor />
      </button>
    </div>
  )
}

export default withErrorBoundary(SearchInput)
