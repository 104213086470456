const SearchIcon: React.FC<{ isPrimaryColor?: boolean }> = ({
  isPrimaryColor
}) => (
  <svg
    id="Group_14"
    data-name="Group 14"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="26"
    viewBox="0 0 24 26"
  >
    <g
      id="Ellipse_2"
      data-name="Ellipse 2"
      transform="translate(0)"
      fill="#fff"
      stroke={isPrimaryColor ? '#e7131a' : '#333'}
      strokeWidth={3}
    >
      <circle cx={9} cy={9} r={9} stroke="none" />
      <circle cx={9} cy={9} r="7.5" fill="none" />
    </g>
    <line
      id="Line_10"
      data-name="Line 10"
      x2="9.42"
      y2="9.42"
      transform="translate(13.278 15.579)"
      fill="none"
      stroke={isPrimaryColor ? '#e7131a' : '#333'}
      strokeWidth={3}
    />
  </svg>
)
export default SearchIcon
